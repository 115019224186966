import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import {
  HelpfulCategories,
  HelpfulCategoriesSelect,
  HelpfulCategory,
  HelpfulCategoryButton,
  HelpfulVideosContent,
  HelpfulVideosMain,
  HelpfulModal,
  HelpfulModalMain,
  HelpfulModalClose,
  HelpfulModalBackground,
} from './index.style'
import {
  disablePageScroll,
  enablePageScroll,
  focusElement,
  scrollTo,
} from '../../utils/utils'
import ScrollSection from '../ScrollSection'
import Spacer from '../Spacer'
import Portal from '../Portal'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import VimeoPlayer from '../VimeoPlayer'
import { Heading1, TitleSmall } from '../TextStyles'
import Video from './Video'
import IconCrossThin from '../svgs/IconCrossThin'
import {
  ContactFormSelect,
  ContactFormSelectLabel,
  ContactFormSelectWrapper,
} from '../ContactForm/index.style'
import AnimateSplitText from '../animation/AnimateSplitText'

const HelpfulVideos = ({ title, categoriesLabel, allLabel, videos }) => {
  const [videoId, setVideoId] = useState()
  const [showModal, setShowModal] = useState(false)
  const showModalRef = useRef()
  const [activeCategory, setActiveCategory] = useState(allLabel)
  const modalTriggerEl = useRef()
  const modalRef = useRef()
  const modalBackgroundRef = useRef()
  const modalVideoRef = useRef()
  const modalCloseRef = useRef()
  const videosRef = useRef()
  const openTl = useRef()
  const activeCategories = []
  const categoriesSelectId = 'video-type'

  for (const video of videos) {
    for (const category of video.category) {
      if (!activeCategories.includes(category) && category !== allLabel) {
        activeCategories.push(category)
      }
    }
  }

  const handleCategorySelect = category => {
    gsap.to(videosRef.current, {
      ease: 'power2.inOut',
      duration: 0.2,
      opacity: 0.3,
      onStart: () =>
        scrollTo(videosRef.current, 400, 0, () => {
          setActiveCategory(category)
          gsap.to(videosRef.current, {
            ease: 'power2.inOut',
            duration: 0.2,
            opacity: 1,
          })
        }),
    })
  }

  const openModal = (videoId, triggerElement) => {
    modalTriggerEl.current = triggerElement
    setShowModal(true)
    showModalRef.current = true
    setVideoId(videoId)
    focusElement(modalRef.current)
    disablePageScroll()

    openTl.current = gsap.timeline()

    openTl.current
      .set(modalRef.current, {
        pointerEvents: 'auto',
      })
      .to(modalBackgroundRef.current, {
        opacity: 1,
        duration: 0.3,
        ease: 'power2.inOut',
      })
      .fromTo(
        modalVideoRef.current,
        {
          yPercent: 50,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          duration: 0.2,
          ease: 'power2.out',
        },
        '>-0.1'
      )
      .fromTo(
        modalCloseRef.current,
        {
          xPercent: 100,
        },
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.2,
          ease: 'power2.out',
        },
        '<0.2'
      )
  }

  const closeModal = useCallback(() => {
    if (showModalRef.current) {
      setShowModal(false)
      showModalRef.current = false
      modalTriggerEl.current.focus()
      openTl.current.reverse()
      enablePageScroll()
    }
  }, [])

  useEffect(() => {
    const handleKeyUp = e => {
      if (e.keyCode === 27) {
        closeModal()
      }
    }

    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [closeModal])

  return (
    <ScrollSection>
      <>
        <Spacer size={[117, 275]} />

        <Container>
          <Grid>
            <GridItem tabletP={10} tabletPStart={2}>
              <Heading1>
                <AnimateSplitText heading gradient>
                  {title}
                </AnimateSplitText>
              </Heading1>
            </GridItem>
          </Grid>
        </Container>

        <Spacer size={[77, 111]} />

        <HelpfulVideosMain>
          <Container>
            <Grid>
              <GridItem id="helpful-categories" tabletL={2} tabletLStart={2}>
                <HelpfulCategoriesSelect>
                  <ContactFormSelectWrapper>
                    <ContactFormSelect
                      id={categoriesSelectId}
                      value={activeCategory}
                      onChange={e =>
                        handleCategorySelect(
                          e.target.options[e.target.selectedIndex].text
                        )
                      }
                    >
                      <option value={allLabel}>{allLabel}</option>

                      {React.Children.toArray(
                        activeCategories.map(category => (
                          <option value={category}>{category}</option>
                        ))
                      )}
                    </ContactFormSelect>

                    <ContactFormSelectLabel
                      show={activeCategory !== ''}
                      htmlFor={categoriesSelectId}
                    >
                      {categoriesLabel}
                    </ContactFormSelectLabel>
                  </ContactFormSelectWrapper>
                </HelpfulCategoriesSelect>

                <HelpfulCategories
                  data-scroll
                  data-scroll-sticky
                  data-scroll-target="#helpful-categories"
                >
                  <HelpfulCategory>
                    <HelpfulCategoryButton
                      active={activeCategory === allLabel}
                      onClick={() => handleCategorySelect(allLabel)}
                    >
                      <TitleSmall>{allLabel}</TitleSmall>
                    </HelpfulCategoryButton>
                  </HelpfulCategory>

                  {React.Children.toArray(
                    activeCategories.map(category => (
                      <HelpfulCategory>
                        <HelpfulCategoryButton
                          active={activeCategory === category}
                          onClick={() => handleCategorySelect(category)}
                        >
                          <TitleSmall>{category}</TitleSmall>
                        </HelpfulCategoryButton>
                      </HelpfulCategory>
                    ))
                  )}
                </HelpfulCategories>
              </GridItem>

              <GridItem tabletL={7} tabletLStart={5}>
                <HelpfulVideosContent ref={videosRef}>
                  <Spacer size={[60, 110]} />

                  {React.Children.toArray(
                    videos.map(video => (
                      <Video
                        hidden={!video.category.includes(activeCategory)}
                        title={video.title}
                        description={video.description.description}
                        ctaLabel={video.ctaLabel}
                        videoId={video.videoId}
                        videoThumbnail={video.videoThumbnail}
                        openModal={openModal}
                      />
                    ))
                  )}
                </HelpfulVideosContent>
              </GridItem>
            </Grid>

            <Spacer size={[204, 302]} />
          </Container>
        </HelpfulVideosMain>

        <Portal>
          <HelpfulModal ref={modalRef}>
            <HelpfulModalBackground
              ref={modalBackgroundRef}
              onClickCapture={closeModal}
            />

            <HelpfulModalClose ref={modalCloseRef} onClick={closeModal}>
              <IconCrossThin responsive />
            </HelpfulModalClose>

            <HelpfulModalMain ref={modalVideoRef}>
              {videoId && (
                <VimeoPlayer
                  id={videoId}
                  fluid
                  autoplay
                  playState={showModal}
                  maxWidthVW={80}
                  maxHeightVH={70}
                />
              )}
            </HelpfulModalMain>
          </HelpfulModal>
        </Portal>
      </>
    </ScrollSection>
  )
}

HelpfulVideos.propTypes = {
  title: PropTypes.string,
  categoriesLabel: PropTypes.string,
  allLabel: PropTypes.string,
  videos: PropTypes.array,
}

export default HelpfulVideos
