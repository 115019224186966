import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import PageNav from '../components/PageNav'
import Footer from '../components/Footer'
import HelpfulVideos from '../components/HelpfulVideos'

const HelpfulVideosPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    categoriesLabel,
    allVideosLabel,
    videos,
    pageNavigation,
  } = data.contentfulHelpfulVideos

  for (const video of videos) {
    video.category.push(allVideosLabel)
  }

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.helpfulVideos}
      />

      <HelpfulVideos
        title={seoTitle}
        categoriesLabel={categoriesLabel}
        allLabel={allVideosLabel}
        videos={videos}
      />

      <PageNav
        title={pageNavigation.title}
        text={pageNavigation.text}
        linkSlug={pageNavigation.pageLink.slug}
        linkText={pageNavigation.linkText}
        image={pageNavigation.image}
      />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          helpfulVideos
        }
      }
    }
    contentfulHelpfulVideos {
      seoTitle
      seoDescription {
        seoDescription
      }
      categoriesLabel
      allVideosLabel
      videos {
        title
        description {
          description
        }
        ctaLabel
        videoId
        videoThumbnail {
          gatsbyImageData
          description
        }
        category
      }
      pageNavigation {
        ...PageLink
      }
    }
  }
`

export default HelpfulVideosPage
