import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  VideoContent,
  VideoMain,
  VideoToggle,
  VideoToggleButton,
  VideoToggleIcon,
} from './index.style'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import IconVideoPlayButton from '../../svgs/IconVideoPlayButton'
import { Heading3, TextBody } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import Spacer from '../../Spacer'
import Cta from '../../Cta'
import { colors } from '../../../styles/vars/colors.style'

const Video = ({
  title,
  description,
  ctaLabel,
  videoId,
  videoThumbnail,
  hidden,
  openModal,
}) => {
  const openVideo = e => {
    openModal(videoId, e.currentTarget)
  }

  return (
    <VideoMain hidden={hidden}>
      <Grid colCount={7}>
        <GridItem deskL={3}>
          <VideoToggle>
            <GatsbyImage
              image={videoThumbnail.gatsbyImageData}
              alt={videoThumbnail.description}
            />

            <VideoToggleButton onClick={openVideo}>
              <VideoToggleIcon>
                <IconVideoPlayButton fill={colors.light} responsive />
              </VideoToggleIcon>
            </VideoToggleButton>
          </VideoToggle>
        </GridItem>

        <GridItem deskL={4}>
          <VideoContent>
            <Heading3 as="h3" color={colors.blue}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>

            <Spacer size={16} />

            <TextBody>{description}</TextBody>

            <Spacer size={[28, 34]} />

            <Cta
              label={ctaLabel}
              color={colors.blue}
              onClick={openVideo}
              button
            />
          </VideoContent>
        </GridItem>
      </Grid>
    </VideoMain>
  )
}

Video.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLabel: PropTypes.string,
  videoId: PropTypes.string,
  videoThumbnail: PropTypes.object,
  hidden: PropTypes.bool,
  openModal: PropTypes.func,
}

export default Video
