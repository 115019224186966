import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'
import { mq } from '../../../styles/vars/media-queries.style'

export const VideoMain = styled.div`
  &:not([hidden]) + & {
    margin-top: 7.6rem;
  }
`

export const VideoToggle = styled.div`
  position: relative;
  margin-bottom: 1.6rem;
  overflow: hidden;
  border-radius: 1.2rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export const VideoToggleButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
`

export const VideoToggleIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 23%;
  max-width: 8.8rem;
  transition: transform 0.15s ${easings.inOut.default};

  ${VideoToggleButton}:hover &,
  ${VideoToggleButton}:focus & {
    transform: translate(-50%, -50%) scale(1.05);
  }
`

export const VideoContent = styled.div`
  ${mq.deskL} {
    padding-left: 6rem;
  }
`
