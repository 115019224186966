import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'

export const HelpfulVideosMain = styled.div`
  border-top: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
`

export const HelpfulCategoriesSelect = styled.div`
  margin-top: 5.2rem;

  ${mq.tabletL} {
    display: none;
  }
`

export const HelpfulCategories = styled.ul`
  display: none;
  ${clamp('padding-top', 52, 92)}
  list-style: none;

  ${mq.tabletL} {
    display: block;
  }
`

export const HelpfulCategory = styled.li``

export const HelpfulCategoryButton = styled.button`
  width: 100%;
  padding-top: 3.4rem;
  padding-bottom: 3.3rem;
  text-align: left;
  cursor: pointer;
  border: none;
  border-bottom: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
  background-color: transparent;

  p {
    opacity: ${props => (props.active ? 1 : 0.5)};
    transition: opacity 0.15s ease;
  }
`

export const HelpfulVideosContent = styled.div``

export const HelpfulModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: none;

  &:focus {
    outline: none;
  }
`

export const HelpfulModalBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${transparentize(0.2, colors.light)};
  opacity: 0;
`

export const HelpfulModalMain = styled.div`
  position: relative;
  opacity: 0;
`

export const HelpfulModalClose = styled.button`
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${clamp('width', 32, 58)}
  ${clamp('height', 32, 58)}
  ${clamp('padding', 9, 20)}
  color: ${colors.dark};
  border: 0.1rem solid ${transparentize(0.5, colors.dark)};
  border-radius: 50%;
  background: ${colors.light};
  opacity: 0;

  ${mq.tabletL} {
    top: 2vw;
    right: 2vw;
  }

  svg {
    width: 100%;
  }
`
